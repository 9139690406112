require("navigation");

new WOW().init();

$(".fancybox").fancybox({

	'transitionIn'	:	'elastic',
	'transitionOut'	:	'elastic',
	'speedIn'		:	600,
	'speedOut'		:	200,
	'overlayShow'	:	true,
	helpers: {
		title : {
			type : 'float'
		}
	}
});

$(".content-top-a").click(function() {
	$('html, body').animate({
		scrollTop: $(".header").offset().top
	}, 500);
});


if($("#cms_forms_1")) {
	if ($('.checkbox').is(':checked')){
		$('.checkbox').prop('checked', false);
	}
}

$(".contactButton").addClass("disabled");
$(".checkbox").click(function() {
	if ($('.checkbox').is(':checked')){
		$(".contactButton").removeClass("disabled");
		$(".contactButton").attr("disabled", false);
	} else {
		$(".contactButton").addClass("disabled");
		$(".contactButton").attr("disabled", true);
	}
	
});





if ($('.contact').length) {
	if ($('.checkbox').is(':checked')){
		$(".contactButton").attr("disabled", false);
	}
}

	