$( document ).ready(function() {
	var oldfocus;
	$('#trigger-overlay').on('click',function() {
		$('.overlay-door').css({
			"z-index": 10000
		});
		$('.overlay-door').animate({
			opacity: 1
		}, 300 );
	});
	$('.overlay-door .overlay-close').on('click',function() {
		$('.overlay-door').animate({
			opacity: 0
		}, {
			duration: 300,
			complete: function() {
				$('.overlay-door').css({"z-index": -1});
			}
		});
	});

	$('.mobile-nav-button').on('click', function() {
		if(oldfocus) {
			$(oldfocus).parent().find('.mobile-nav-a.CMS_lvl2').removeClass('toggled');
			$(oldfocus).parent().find('ul.CMS_lvl3').slideToggle(function() {

			});
			$(oldfocus).removeClass('toggled');
		}
		$(this).toggleClass('toggled');
		$(this).parent().find('.mobile-nav-a.CMS_lvl2').toggleClass('toggled');
		$(this).parent().find('ul.CMS_lvl3').slideToggle(function() {

		});
		oldfocus = $(this);
	});
});
